export default [
    {
        id: 3,
        uri: "medicina",
        name: "Medicina",
        brief: "Desvende a Complexidade do Corpo Humano<br><br><br>O corpo humano é uma das maravilhas mais complexas da natureza, uma sinfonia de sistemas interconectados que trabalham harmoniosamente para nos manter vivos. Desde o intricado funcionamento do coração até os segredos escondidos no interior do cérebro, o corpo humano é uma fonte infinita de fascinação.<br><br>Na FACISO/Sinop-MT, incentivamos a exploração e o entendimento dessa complexidade. Em nossas salas de aula, laboratórios e programas de pesquisa, convidamos você a embarcar em uma jornada de descoberta, a desvendar os mistérios do corpo humano e a compreender como ele responde ao mundo ao nosso redor.<br><br>Nossos educadores e pesquisadores estão comprometidos em guiá-lo nessa jornada, fornecendo as ferramentas e o conhecimento necessários para explorar cada faceta do corpo humano. À medida que você desvenda a complexidade, também desbloqueia um mundo de oportunidades no campo da saúde, da pesquisa médica e da inovação.<br><br>Juntos, vamos explorar, aprender e inspirar a próxima geração de cientistas, médicos e profissionais de saúde. Venha conosco desvendar a complexidade do corpo humano e moldar um futuro mais saudável e promissor para todos.<br><br><b>Torne-se um agente do aperfeiçoamento da saúde coletiva.</b>",
        icon: "../../../assets/img/icon-gestao-hospitalar.png",
        meta_description:
            "O curso de Medicina da Faculdade Faciso, está estruturado para responder às suas necessidades de formação do profissional.",
        meta_keywords:
            "graduação, vestibular, medicina",
        describe:
            "<p>A Faculdade de Ciências da Saúde Dr. Oswaldo Fortini (FACISO/Sinop-MT) possui um campus que proporciona ao estudante de medicina toda a estrutura necessária para gerar conhecimento científico de excelência, por meio de um processo formativo inovador, que valorize a humanização, a equidade e a ética, para o desenvolvimento pessoal e profissional comprometido com a transformação social.<br><br>A FACISO/Sinop-MT formará profissionais competentes, críticos, possuidores de valores éticos e políticos, comprometidos com a reconstrução da sociedade.<br><br>O curso de medicina da FACISO/Sinop-MT, atende às Diretrizes Curriculares Nacionais e proporciona aos futuros médicos formação técnica, não técnica, humanista, reflexiva e ética para atuar nos diferentes níveis de atenção à saúde, com ações de promoção, prevenção, recuperação e reabilitação da saúde, nos campos individual e coletivo, com responsabilidade social e compromisso com a defesa da cidadania e dignidade humana. Conta com corpo docente altamente qualificado, infraestrutura inovadora e proposta pedagógica que utiliza metodologias ativas de ensino-aprendizagem.<br><br>Estrutura:<br><ul><li>Laboratórios</li><li>Biblioteca (física e virtual)</li><li>Sistema de gestão acadêmica e ambiente virtual de aprendizagem como apoio aos estudos presenciais</li></ul></p>",
        describe_imagem: "../../../assets/img/estudante_1.png",
        duration:
            " 12 semestres (6 anos)",
        action: [
            {
                type: false,
                imagem: "../../../assets/img/icon-area-de-atualizacao.png",
                name: "Período / Modalidade",
                describe:
                    "<ul><li>Integral</li><li>Presencial</li></ul>",
            },
            {
                type: true,
                imagem: "../../../assets/img/icon-maleta.png",
                name: "Formação",
                describe:
                    '<ul><li>Médico</li></ul>',
            },
        ],
    },
    {
        id: 4,
        uri: "saude-coletiva-presencial",
        name: "Saúde Coletiva",
        brief: "Desvende a Complexidade do Corpo Humano<br><br><br>O corpo humano é uma das maravilhas mais complexas da natureza, uma sinfonia de sistemas interconectados que trabalham harmoniosamente para nos manter vivos. Desde o intricado funcionamento do coração até os segredos escondidos no interior do cérebro, o corpo humano é uma fonte infinita de fascinação.<br><br>Na FACISO/Sinop-MT, incentivamos a exploração e o entendimento dessa complexidade. Em nossas salas de aula, laboratórios e programas de pesquisa, convidamos você a embarcar em uma jornada de descoberta, a desvendar os mistérios do corpo humano e a compreender como ele responde ao mundo ao nosso redor.<br><br>Nossos educadores e pesquisadores estão comprometidos em guiá-lo nessa jornada, fornecendo as ferramentas e o conhecimento necessários para explorar cada faceta do corpo humano. À medida que você desvenda a complexidade, também desbloqueia um mundo de oportunidades no campo da saúde, da pesquisa médica e da inovação.<br><br>Juntos, vamos explorar, aprender e inspirar a próxima geração de cientistas, médicos e profissionais de saúde. Venha conosco desvendar a complexidade do corpo humano e moldar um futuro mais saudável e promissor para todos.<br><br><b>Torne-se um agente do aperfeiçoamento da saúde coletiva.</b>",
        icon: "../../../assets/img/icon-gestao-hospitalar.png",
        meta_description:
            "O curso de Graduação em Saúde Coletiva da Faculdade Faciso, na modalidade presencial, está estruturado para responder às necessidades de formação do profissional sanitarista.",
        meta_keywords:
            "graduação, vestibular, saúde coletiva, curso de saúde coletiva",
        describe:
            "<p>O Curso de <b>Graduação em Saúde Coletiva da FACISO/Sinop-MT</b>, na modalidade presencial, está estruturado para responder às necessidades de formação do profissional sanitarista. Historicamente, este profissional é considerado como um trabalhador do campo da Saúde Coletiva, com formação generalista, que atua em todos os níveis de complexidade do SUS.</p><p>É preparado para formular, implantar, organizar, monitorar e avaliar políticas, planos, programas, projetos e serviços de saúde.</p><p>É um profissional comprometido ética e politicamente com a valorização e a defesa da vida, a preservação do meio ambiente e a cidadania no atendimento às necessidades sociais em saúde. É, portanto, o trabalhador da saúde na sua dimensão coletiva. Além disso, este profissional poderá desempenhar funções nos subsistemas privados de atenção à saúde; no sistema de regulação; no terceiro setor e diretamente nas organizações da sociedade civil.</p>",
        describe_imagem: "../../../assets/img/estudante_1.png",
        duration:
            " 8 (oito) semestres, no mínimo, e de 14 (quatorze), no máximo.",
        action: [
            {
                type: false,
                imagem: "../../../assets/img/icon-area-de-atualizacao.png",
                name: "Áreas de Atuação",
                describe:
                    "O Curso de Saúde Coletiva aborda o processo saúde-doença, analisa as necessidades de saúde em geral e de grupos sociais específicos, a atenção integral da saúde no sistema regionalizado e hierarquizado de referência e contrarreferência com ênfase no trabalho em equipe e no Sistema Único de Saúde (SUS).",
            },
            {
                type: true,
                imagem: "../../../assets/img/icon-maleta.png",
                name: "Mercado de trabalho",
                describe:
                    'O curso no seu desenvolvimento aborda conteúdos relacionados às ciências exatas, como o processo, os métodos e as abordagens estatísticas como suporte à gestão em saúde; conhecimentos das ciências biológicas e da saúde; conhecimentos das ciências humanas e sociais, envolvendo a relação indivíduo/estado e sociedade<span class="show dois">...</span><span class="hidden dois">, a comunicação, a educação, a economia, a gestão e a administração. <p>São estudados ainda os conteúdos da Saúde Coletiva que incluem os conhecimentos teóricos e práticos relacionados às políticas de saúde, as concepções, os modelos de organização da atenção à saúde, o planejamento e a programação, os sistemas comparados de saúde. Aborda ainda a participação, o controle social, o financiamento do setor de saúde, o processo de trabalho em saúde e o processo da Reforma Sanitária.</p></span>',
            },
        ],
    }
];

import { Component, OnInit } from '@angular/core';

@Component({
    selector: "contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
    whatsapp = "https://api.whatsapp.com/send?phone=5562981472042";
    messager = "https://www.facebook.com/Faciso";
    email = "graduacao@facisosinop.com.br";
    callToAction = "https://wa.me/5562981472042";
}
